import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

const HeaderWrapper = styled.header`
  grid-area: 1 / 1 / auto / -1;
  
  &.home{
    height: calc(100vh - 6rem); /* Fallback for browsers that do not support Custom Properties */
    height: calc( calc(var(--vh, 1vh) * 100) - 6rem);
    position: relative;

    >div{
      position: sticky;
      top: 1rem;
    }
  }

  h1 {
    margin: 0;

    a {
      color: #1a1a1a;
      text-decoration: none;
    }

  }

  h2 {
    margin: 0;
    text-transform: none;
    line-height: 1.25;
    
    a {
      display: block;
      color: #1a1a1a;
      text-decoration: none;
      font-style: normal;
    }

  }

  svg {
    width: 100%;
    display: block;
  }

  @media (min-width: 45em){
    grid-area: 1 / 1 / auto / 3;

    &.home{
      grid-area: 1 / 1 / auto / -1;
      height: auto;
  
      >div{
        position: relative;
        top: 0;
      }
    }
    
  }
  @media (min-width: 60em){
    h1 {
      
    }
  }
  @media (min-aspect-ratio: 8/5){
    &.home{

      h1 {
        height: 100vh; /* Fallback for browsers that do not support Custom Properties */
        height: calc( calc(var(--vh, 1vh) * 100));
        svg{
          height: 100%;
          width: auto;
          max-width: 100%;
        }
      }

    }
  }
`;

const HeaderContainer = styled.div`
  margin: 0 auto; 
  padding: 0 0; 
  
  .annotation-wrapper{
    display: inline-block;
    cursor: help;
  }
  @media (min-width: 30em){
    position: relative;
    top: 0;
  }
  @media (min-aspect-ratio: 8/5){
    position: relative;
    top: 0;
  }
`;

const Header = ({ location, setAnnotation, setAnnotationAlert }) => {
  const data = useStaticQuery(graphql`
    query HeaderSVGCopyQuery {
      headerJson {
        line_1
        line_2
        line_3
        line_4
        line_5
        annotation
        annotation_mark
      }
    }
  `)

  return(
    <HeaderWrapper className={location.pathname === '/' ? 'home': ''} >
      <HeaderContainer>
        { location.pathname === '/' ?
          <h1>
            <svg
              viewBox="0 0 614 400"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="xMinYMin meet"
            >
              <text
                x="0"
                y="0"
                fontSize="64"
                fontWeight="700"
                fill="black"
              >
                <tspan x="0" dy="64">{ data.headerJson.line_1 }</tspan>
                <tspan x="0" dy="80">{ data.headerJson.line_2 }</tspan>
                <tspan x="0" dy="80">{ data.headerJson.line_3 }</tspan>
                <tspan x="0" dy="80">{ data.headerJson.line_4 }</tspan>
                <tspan x="0" dy="80">{ data.headerJson.line_5 }*</tspan>
              </text>
            </svg>
          </h1>
          
        :
          <h2>
            <Link
              to="/"
            >
              <svg
                width="100%"
                viewBox="0 0 614 400"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="xMinYMin meet"
              >
                <text
                  x="0"
                  y="0"
                  fontSize="64"
                  fontWeight="700"
                  fill="black"
                >
                  <tspan x="0" dy="64">{ data.headerJson.line_1 }</tspan>
                  <tspan x="0" dy="80">{ data.headerJson.line_2 }</tspan>
                  <tspan x="0" dy="80">{ data.headerJson.line_3 }</tspan>
                  <tspan x="0" dy="80">{ data.headerJson.line_4 }</tspan>
                  <tspan x="0" dy="80">{ data.headerJson.line_5 }*</tspan>
                </text>
              </svg>
            </Link>
          </h2>
        }
      </HeaderContainer>
    </HeaderWrapper>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

