/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./HeaderSVG"
import Cube from "./Cube"
import Footer from "./Footer"
import "./layout.css"

const LayoutContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-column-gap: .5rem;
  grid-row-gap: 6rem;
  padding: 1rem;
  margin: 0 auto 0 0;
  max-width: 96rem;

  @media (min-width: 45em){
    grid-column-gap: 1rem;
    grid-row-gap: 12rem;
    padding: 0 2rem 2rem;
  }
`;

const MainLayout = styled.main`
  grid-area: auto / 1 / auto / -1;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-column-gap: .5rem;
  grid-row-gap: 3rem;

  @media (min-width: 45em){
    grid-column-gap: 1rem;
    grid-row-gap: 6rem;
  }
`;

const Layout = ({ children, location, annotation, setAnnotation, annotationAlert, setAnnotationAlert}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)


  return (
    <LayoutContainer>

      <Header location={location} siteTitle={data.site.siteMetadata.title} annotation={ annotation } setAnnotation={ setAnnotation } annotationAlert={ annotationAlert } setAnnotationAlert={ setAnnotationAlert }/>

      <Cube location={location} annotation={ annotation } annotationAlert={ annotationAlert }/>

      <MainLayout>
          
        {children}

      </MainLayout>

      <Footer /> 

    </ LayoutContainer>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout