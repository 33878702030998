import React, { Component } from 'react'
import styled from "styled-components"
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const CubeWrapper = styled.aside`
    grid-area: 1 / 6 / auto / -1;
    width: 100%;
    z-index: -1;

    
    --cubetrans: calc( var(--cubesize) / 2);
    --cubepersp: calc( var(--cubesize) * 2);
    --cubeplanetrans: calc( var(--cubesize) / -2);
    --borderColorDesktop: #1a1a1a;
    --borderColorMobile: #1a1a1a;


    

    /* Declare Animation transforms but on state-cahnge variables 
    .cube-wrapper {
        transform: translateY( calc( -1 * calc( var(--animationPosition) * var(--windowHeightMobile) ) ) );
    }
    .cube.show-front  { 
        transform: translateZ(var(--cubeplanetrans)) rotateX( calc( var(--animationPosition) * 180deg) ); 
    }
    .scene-plane{
        transform: rotate( calc( var(--animationPosition) * 360deg) );
    }
    */

    .cube-wrapper{
        position: fixed;
        bottom: 1rem;
        right: 1rem;
        opacity: var(--cubeOpacity);
        
        /*
        cursor: move; 
        cursor: grab;
        cursor: -moz-grab;
        cursor: -webkit-grab;
        */

        &.home{
            --borderColorMobile: #1a1a1a;
        }
    }
    .scene {
        width: var(--cubesize);
        height: var(--cubesize);
        margin: 0 auto;
        perspective: var(--cubepersp);

        transition: background-color 0.25s ease 0s;
    }
    .scene-plane{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border: 1px dashed #999;
        /*
        .top {
            position: absolute;
            left: 0;
            width: 100%;
            top: 0;
            height: 1px;
            background-image: linear-gradient(to right, #999 0%, #999 25%, transparent 25%);
            background-position: 0 bottom;
            background-size: 8px 1px;
            background-repeat: repeat-x;
        }
        .bottom {
            position: absolute;
            left: 0;
            width: 100%;
            bottom: 0;
            height: 1px;
            background-image: linear-gradient(to right, #999 0%, #999 25%, transparent 25%);
            background-position: 0 bottom;
            background-size: 8px 1px;
            background-repeat: repeat-x;
        }
        .right {
            position: absolute;
            top: 0;
            width: 1px;
            right: 0;
            height: 100%;
            background-image: linear-gradient(to bottom, #999 0%, #999 25%, transparent 25%);
            background-position: left 0;
            background-size: 1px 8px;
            background-repeat: repeat-y;
        }
        .left {
            position: absolute;
            top: 0;
            width: 1px;
            left: 0;
            height: 100%;
            background-image: linear-gradient(to bottom, #999 0%, #999 25%, transparent 25%);
            background-position: left 0;
            background-size: 1px 8px;
            background-repeat: repeat-y;
        }
        */
    }
    .cube{
        width: 100%;
        height: 100%;
        position: relative;
        transform-style: preserve-3d;
        transform: translateZ(var(--cubeplanetrans));
    }

    .plane-front{
        transform: rotateY(  0deg) translateZ(var(--cubetrans));
    }
    .plane-back{
        transform: rotateY(180deg) translateZ(var(--cubetrans));
    }
    .plane-top{
        transform: rotateX( 90deg) translateZ(var(--cubetrans));
    }
    .plane-bottom{
        transform: rotateX(-90deg) translateZ(var(--cubetrans));
    }
    .plane-right{
        transform: rotateY( 90deg) translateZ(var(--cubetrans));
    }
    .plane-left{
        transform: rotateY(-90deg) translateZ(var(--cubetrans));
    }

    .outer-plane{
        position: absolute;
        width: 100%;
        height: 100%;
        border: 1px solid var(--borderColorMobile);
    }
    .plane-x-1{
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        left: calc(100% / 3);
        width: 0px;
        border-right: 1px dashed var(--borderColorMobile);
        /*background-image: linear-gradient(to bottom, var(--borderColorMobile) 0%, var(--borderColorMobile) 25%, transparent 25%);
        background-position: left 0;
        background-size: 1px 8px;
        background-repeat: repeat-y;*/
    }
    .plane-x-2{
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        left: calc( calc(100% / 3) * 2);
        width: 0px;
        border-right: 1px dashed var(--borderColorMobile);
        /*background-image: linear-gradient(to bottom, var(--borderColorMobile) 0%, var(--borderColorMobile) 25%, transparent 25%);
        background-position: left 0;
        background-size: 1px 8px;
        background-repeat: repeat-y;*/
    }

    .plane-y-1{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        top: calc(100% / 3);
        height: 0px;
        border-top: 1px dashed var(--borderColorMobile);
        /*background-image: linear-gradient(to right, var(--borderColorMobile) 0%, var(--borderColorMobile) 25%, transparent 25%);
        background-position: 0 bottom;
        background-size: 8px 1px;
        background-repeat: repeat-x;*/
    }
    .plane-y-2{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        top: calc( calc(100% / 3) * 2);
        height: 0px;
        border-top: 1px dashed var(--borderColorMobile);
        /*background-image: linear-gradient(to right, var(--borderColorMobile) 0%, var(--borderColorMobile) 25%, transparent 25%);
        background-position: 0 bottom;
        background-size: 8px 1px;
        background-repeat: repeat-x;*/
    }

    .border-plane {
       display: none;
    }

    .plane-mid{
        border: none;
    }
    .plane-text-wrapper{
        display: none;
        position: relative;
        width: 100%;
        height: 100%;
    }
    .plane-text{
        display: table-cell;
        vertical-align: middle;
    }
    .plane-text span{
        line-height: 1;
        font-family: "Nimbus Sans L", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
        font-size: .5rem;
        text-transform: uppercase;
        margin: 0 .5rem;
        display: inline-block;
    }
    .plane-text span {
        opacity: 1;
        transition: 0.5s ease opacity;
    }
    .plane-text span:empty {
        opacity: 0;
    }
    
    .outer-plane, .outer-plane > div {
        transition: 0.25s ease background, 0.75s ease border-color;
    }
    .dragging .plane-front, .dragging .plane-back, .dragging .plane-left, .dragging .plane-right, .dragging .plane-top, .dragging .plane-bottom{
        background-color: #1a1a1a26;
        border-color: #fffffa;
        --borderColorDesktop: #fffffa;
    }
    .dragging .outer-plane > div{
        border-color: #fffffa;
    }
    .annotation-trigger{
        transition: 0.25s ease transform;
    }
    .annotation-trigger.no-flip{
        transform: none !important;
    }

    @media (min-width: 45em){
        grid-area: 1 / 9 / auto / -1;
        bottom: 2rem;
        right: 2rem;
        z-index: unset;

        .cube-wrapper{
            bottom: 2rem;
            right: 2rem;
            opacity: 1;
            
            &.landing.home{
                --borderColorMobile: #ebebeb;
            }
        }

        /* Declare Animation transforms but on state-cahnge variables 
        .cube-wrapper {
            transform: translateY( calc( -1 * calc( var(--animationPosition) * var(--windowHeightDesk) ) ) );
        }
        */

        .border-plane {
            display: block;
            width: 150%;
            height: 150%;
            left: -25%;
            top: -25%;
            position: absolute;
            
            z-index: 9;
        }

        .plane-text-wrapper{
            display: table;
        }
        .plane-text span{
            font-size: 1rem;
        }
        .outer-plane, .outer-plane > div {
            transition: 0.25s ease background, 0.25s ease border-color;
        }
        .outer-plane, .plane-x-1, .plane-x-2, .plane-y-1, .plane-y-2{
            border-color: var(--borderColorDesktop);
        }
        .plane-x-1, .plane-x-2{
            /*background-image: linear-gradient(to bottom, var(--borderColorDesktop) 0%, var(--borderColorDesktop) 25%, transparent 25%);*/
        }
        .plane-y-1, .plane-y-2{
            /*background-image: linear-gradient(to right, var(--borderColorDesktop) 0%, var(--borderColorDesktop) 25%, transparent 25%);*/
        }
        .plane-mid{
            border: none;
        }
    }
    @media (min-width: 60em){
        .plane-text span{
            font-size: 1.25rem;
        }
    }
    @media (min-width: 75em){
        .plane-text span{
            font-size: 1.5rem;
        }
    }
    @media (min-width: 90em){
        .plane-text span{
            font-size: 1.75rem;
        }
    }
`;

export default class Cube extends Component {

    state = {
        x: 50,
        y: 50,
        z: 0,
        prevX: 200,
        prevY: 200,
        prevZ: 0,
        mouseX: 0,
        mouseY:0,
        drag: false,
        initiateDrag: true,
        keyFrame: 0,
        cubePosition: 0,
        cubeRotation: 0,
        sceneRotation: 0,
        cubeSize: 0,
        cubeScroll: 1,
        scrollPosition: 0,
        animations: [],
        pageY: 0,
        ticking: false,
        dimensions: {
            w: 0,
            h: 0,
            o: 0,
            bodyH: 0,
        },
        annotationFlip: false,
    };
  
    componentDidMount() {
        
        gsap.registerPlugin(ScrollTrigger);

        let tl = gsap.timeline({
        scrollTrigger: {
            scrub: true,
            toggleClass: "scrubbing"
        }
        });

        //console.log(window)
        //console.log("Window inner: " + window.innerHeight)
        //console.log("Cube: " + document.querySelector('#cubeContainer').offsetWidth)
        //console.log(-1 * (window.innerHeight - document.querySelector('#cubeContainer').offsetWidth - 64))
        function createTimeline(){

            let mql = window.matchMedia('(min-width: 45em)'),
                verticalTrack = 0;
            if(mql.matches){
                verticalTrack = -1 * (window.innerHeight - document.querySelector('#cubeContainer').offsetWidth - 64);
            }else{
                verticalTrack = -1 * (window.innerHeight - document.querySelector('#cubeContainer').offsetWidth - 32);
            }

            tl.fromTo("[data-cube-wrapper]", 
            {
                y: 0
            },
            {
                y: verticalTrack,
                ease: "none"
            });
            
            tl.fromTo("[data-cube]", 
            {
                rotationX: 0,
                translateZ: document.querySelector('#cubeContainer').offsetWidth / -2
            },
            {
                rotationX: 180,
                translateZ: document.querySelector('#cubeContainer').offsetWidth / -2,
                ease: "none"
            }, 0 );
    
            tl.fromTo("[data-plane]", 
            {
                rotation: 0
            },
            {
                rotation: 360,
                ease: "none"
            }, 0 );
            
            
        }
        

        createTimeline();

        //try to refresh after a timeout since componentDidMount isn't getting the entire page length
        setTimeout(function(){ 
            //console.log("refresh")
            tl.scrollTrigger.refresh();
        }, 500);
        

        /*
        tl.to("[data-cube]", {
            rotationX: 180,
            ease: "power2",
            duration: 1
        }, 0 );

        tl.to("[data-plane]", {
            rotation: 360,
            ease: "power2",
            duration: 1
        }, 0 );
        */

        window.addEventListener('resize', (evt) => {
            tl.clear();
            createTimeline();
            this.handleResize();
        }, { capture: false, passive: true});

        
        document.addEventListener('scroll', (evt) => {
            this.handleScroll();
        }, { capture: false, passive: true});
        
        this.initSize();
        this.handleResize();

        this.timeline = tl;
    }
  
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.handleResize);
    }

    componentDidUpdate() {
        //console.log("updated")
        //console.log(this.timeline)
        this.timeline.scrollTrigger.refresh()

        const { annotation, annotationAlert } = this.props;
        //const { dimensions } = this.state;

        const annotationTriggerRef = this.annotationTrigger.current;

        if(annotation && annotationAlert){
            
            //var scrollPos = (window.pageYOffset/(document.body.clientHeight-dimensions.h))*document.body.clientHeight;
            //var animationPos = (scrollPos / document.body.clientHeight) * 1000;

            //var rotationalPos = 180 * (Math.floor(animationPos) / 1000);

            let rotationalPos = this.timeline.progress() * -180;
            annotationTriggerRef.style.transform = "rotateX("+rotationalPos+"deg)";
            
            
        }
        
    }
    /*
    handleAnimations = (e) => {
        const cubeWrapperRef = this.cubeWrapper.current;
        const cubeContainerRef = this.cubeContainer.current;
        const sceneRef = this.scenePlane.current;
        const cubeRef = this.cube.current;

        //console.log(document.body);
        //console.log("Client Height at start: "+document.body.clientHeight);
        
        var cubeAnimate = cubeRef.animate([
                {
                    transform: 'translateZ(var(--cubeplanetrans)) rotateX(0deg)'
                },
                {
                    transform: 'translateZ(var(--cubeplanetrans)) rotateX(360deg)'
                }
            ], 
            {
                duration: 2000,
                easing: 'linear',
                iterations: 'Infinity'
            }
        );
        //console.log(cubeAnimate);
        

        var sceneAnimate = sceneRef.animate([
                {
                    transform: 'rotate(0deg)'
                },
                {
                    transform: 'rotate(360deg)'
                }
            ], 
            {
                duration: 1000,
                easing: 'linear',
                iterations: 'Infinity'
            }
        );
        // offset value comes from height of cube plus 4 rem units
        var cubeOffset = cubeContainerRef.offsetWidth + 64;
        //console.log('Cube offset variable: '+cubeOffset);
        
        var cubePosAnimate = cubeWrapperRef.animate([
                {
                transform: 'translateY(0)'
                },
                {
                transform: 'translateY(calc(-100vh + '+cubeOffset+'px))'
                },
            ], 
            {
                duration: 1001,
                easing: 'linear',
                iterations: 'Infinity'
            }
        );
        
        var animationArray = [];
        //animationArray.push(cubeAnimate, cubePosAnimate, sceneAnimate);
        animationArray.push(sceneAnimate);
        animationArray.forEach(animation => {
            animation.pause();
        });

        this.setState({
            animations: animationArray,
        });
    }
    */
    onScroll = (e) => {
        
        this.setState({
            pageY: window.pageYOffset
        });

        this.requestAnimationTick();
    }

    requestAnimationTick = (e) => {

        const { ticking } = this.state;

        if(!ticking) {
            requestAnimationFrame(this.handleScroll);
        }
        
        this.setState({
            ticking: true
        });
    }

    initSize = (e) => {
        const { innerWidth: width, innerHeight: height, pageYOffset: offset } = window;

        this.handleCubeSize();
        
        this.setState({
            dimensions: {
                w: width,
                h: height,
                o: offset,
                bodyH: document.body.clientHeight,
            },
        });
        
    }

    handleResize = (e) => {
        const { innerWidth: width, innerHeight: height, pageYOffset: offset } = window;

        this.handleCubeSize();

        //this.handleAnimations();
        
        let viewportWidth = width || document.documentElement.clientWidth;
        if( viewportWidth >= 720 ) {
            this.setState({
                dimensions: {
                    w: width,
                    h: height,
                    o: offset,
                    bodyH: document.body.clientHeight,
                },
            })
        }

        
    }
  
    handleScroll = (e) => {

        // reset the tick so we can
        // capture the next onScroll
        /*
        this.setState({
            ticking: false
        });
        */

        const { dimensions } = this.state;

        let scrollPos = (window.pageYOffset/(document.body.clientHeight-dimensions.h))*document.body.clientHeight;

        //console.log(scrollPos);

        
        this.setState({
            scrollPosition: scrollPos
        });
        

        //let animationPos = (scrollPos / document.body.clientHeight);

        // Update cube position value
        //let windowHeightAdjusted = dimensions.h - cubeSize - cubeBounds;
        //let cubePos = animationPos * windowHeightAdjusted;

        // Update cube rotation value
        //let cubeRotationMax = 180;
        //let cubeRotation = animationPos * cubeRotationMax;

        // Update scene rotation value
        //let sceneRotationMax = 360;
        //let sceneRotation = animationPos * sceneRotationMax;
        
        /*
        let viewportWidth = dimensions.w || document.documentElement.clientWidth;
        let desktop = ( viewportWidth >= 720 ) ? true : false;

        if(scrollPos <= 100 && !desktop){

            let o = 1.025 - (0.01 * scrollPos);
            if(o > 1){
                o = 1;
            }

            //update state w/ opacity change
            this.setState({
                cubeScroll: o,
                scrollPosition: scrollPos
            });

        }else{
            //update state w/o opacity change
            
        }
        */
        
    }

    handleCubeSize = (e) => {
        //console.log("setting cube size");
        //set cube size
        //const cubeContainerRef = this.cubeContainer.current;
        //cubeContainerRef.style.setProperty('--cubesize', cubeContainerRef.offsetWidth+'px');
        let cubeSize = document.querySelector('#cubeContainer').offsetWidth;
        //console.log("Container Size: "+cubeContainerRef.offsetWidth);
        this.setState({ cubeSize: cubeSize });
    }

    onMouseMove = (e) => {

        if(this.state.drag){
            var rect = e.target.getBoundingClientRect();
            var eX= e.clientX - rect.left; //x position within the element.
            var eY = e.clientY - rect.top;  //y position within the element.
            var percentX, percentY, zVal = 0;

            this.setState({mouseX: e.clientX - rect.left, mouseY: e.clientY - rect.top})
        
            zVal = (((eX / rect.width) *10) - 5)*10;
            percentX = 50 + (((eX / rect.width) *10) - 5)*10;
            percentY = 50 + (((eY / rect.height) *10) - 5)*10;
            

            this.setState({ x: percentX, y: percentY, z: zVal });
        }
        
    }

    onMouseDown = (e) => {

        let body = document.getElementsByTagName('body')[0];
        body.className += ' disable-select';
        //body.className = body.className.replace( ' disable-select', '' );

        this.setState({ drag: true });
    }

    onMouseUp = (e) => {

        let body = document.getElementsByTagName('body')[0];
        body.className = body.className.replace( ' disable-select', '' );

        this.setState({ drag: false });
    }

    onMouseLeave = (e) => {

        let body = document.getElementsByTagName('body')[0];
        body.className = body.className.replace( ' disable-select', '' );

        this.setState({ drag: false });
    }

    /*
    getScrollPercent = e => {
        var h = document.documentElement, 
            b = document.body,
            st = 'scrollTop',
            sh = 'scrollHeight';
        return (h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100;
    }
    */
    
    constructor(props) {
        super(props);
        this.cube = React.createRef();
        this.scenePlane = React.createRef();
        this.cubeWrapper = React.createRef();
        this.sceneWrapper = React.createRef();
        this.cubeContainer = React.createRef();
        this.annotationTrigger = React.createRef();
    }

    render() {
        
        const { x, y, z, drag, annotationFlip, cubeSize, scrollPosition } = this.state;
        const { location, annotation, annotationAlert } = this.props;
        
        return (
            <CubeWrapper 
            id="cubeContainer"
            ref={this.cubeContainer}
            data-sal="fade"
            data-sal-delay="450"
            data-sal-duration="500"
            data-sal-easing="ease-out"
            style={{
                zIndex: drag ? 999 : '',
                "--cubesize": `${cubeSize}px`
            }}
            >
                

                <div data-cube-wrapper ref={this.cubeWrapper} className={`cube-wrapper  ${location.pathname === '/' ? 'home': ''}`} style={{ "--cubeOpacity": `${location.pathname === '/' ? (scrollPosition <= 100) ? 1.1 - (0.01 * scrollPosition) : .1 : .1}` }} >
                    <div role="presentation" onMouseLeave={this.onMouseLeave.bind(this)} onMouseDown={this.onMouseDown.bind(this)} onMouseUp={this.onMouseUp.bind(this)} onMouseMove={this.onMouseMove.bind(this)} ref={this.sceneWrapper} className="border-plane"></div>
                    <div className={"scene " + (drag? "dragging":"")} style={{ perspectiveOrigin: `${x}% ${y}%`, transform: `rotateZ(${z}deg)` }}>
                        {/* style={{ perspectiveOrigin: `${x}% ${y}%`, transform: `rotateZ(${z}deg)` }} */}
                        {/* ${cubeScroll ? 'scroll' : 'landing'} */}
                        
                        <div data-plane ref={this.scenePlane} className="scene-plane">
                            {/* style={{ transform: `rotate(${sceneRotation}deg)` }} */}
                            <div className="top"></div>
                            <div className="right"></div>
                            <div className="bottom"></div>
                            <div className="left"></div>
                        </div>
                        <div data-cube ref={this.cube} className="cube show-front">
                            {/*style={{ transform: `translateZ(var(--cubeplanetrans)) rotateX(${cubeRotation}deg)`}}*/}
                            <div className="outer-plane plane-front">
                                <div className="plane-x-1"></div>
                                <div className="plane-x-2"></div>
                                <div className="plane-y-1"></div>
                                <div className="plane-y-2"></div>
                                
                            </div>

                            
                            <div className="outer-plane plane-mid">
                                <div className="plane-text-wrapper"><div ref={this.annotationTrigger} style={{ transform: `rotateX(${annotationFlip}deg)` }} className={"plane-text annotation-trigger " + (annotationAlert? "flip":"no-flip")}><span>{ annotation }</span></div></div>

                            </div>

                            <div className="outer-plane plane-back">
                                <div className="plane-x-1"></div>
                                <div className="plane-x-2"></div>
                                <div className="plane-y-1"></div>
                                <div className="plane-y-2"></div>
                            </div>
                            <div className="outer-plane plane-top">
                                <div className="plane-x-1"></div>
                                <div className="plane-x-2"></div>
                                <div className="plane-y-1"></div>
                                <div className="plane-y-2"></div>
                            </div>
                            <div className="outer-plane plane-bottom">
                                <div className="plane-x-1"></div>
                                <div className="plane-x-2"></div>
                                <div className="plane-y-1"></div>
                                <div className="plane-y-2"></div>
                            </div>
                            <div className="outer-plane plane-right">
                                <div className="plane-x-1"></div>
                                <div className="plane-x-2"></div>
                                <div className="plane-y-1"></div>
                                <div className="plane-y-2"></div>
                            </div>
                            <div className="outer-plane plane-left">
                                <div className="plane-x-1"></div>
                                <div className="plane-x-2"></div>
                                <div className="plane-y-1"></div>
                                <div className="plane-y-2"></div>
                            </div>
                        </div>
                    </div>
                </div>

            </CubeWrapper>
        );
    }
}
